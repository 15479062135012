import request from "@/api/service";

const api = {
   select: '/pms/hardware/getList',
   config: '/pms/hardwareConfig',
   brandCode: '/pms/hardwareConfig/getByBrandCode',
   connect: '/pms/lock/checkConnect'
};

export function getHardwareList(params) {
   return request({
      url: api.select,
      method: 'GET',
      params
   })
}

export function saveConfig(method, params) {
   return request({
      url: api.config,
      method,
      data: params
   })
}

export function getByBrandCode(params) {
   return request({
      url: api.brandCode,
      method: 'GET',
      params
   })
}

export function checkConnect() {

}
