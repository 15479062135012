<template>
  <div class="cont">
    <Breadcrumb :crumbs="hardwareType === 'DOOR_LOCK' ? crumbs1 : crumbs2"/>
    <el-row class="cont-form-box">
      <el-form ref="form" :model="formData" :rules="rules">
        <el-form-item :label="`${ hardwareType === 'DOOR_LOCK' ? '门锁' : '身份证读卡器' }选择`" prop="hardwareId">
          <el-select class="width-350" v-model="formData.hardwareId" @change="handleCodeChange($event)">
            <el-option v-for="item in brandOpt" :key="item.id" :label="item.brandName"
                       :value="item.id"></el-option>
          </el-select>
          <el-button @click="handleConnect" class="m-left-10" size="medium" type="primary">检查连接</el-button>
          <span v-if="connectRes" class="connect-tip el-icon-success">{{ connectRes }}</span>
        </el-form-item>
        <el-form-item label="监听地址" prop="listeningAddress">
          <el-input class="width-350" v-model.trim="formData.listeningAddress" placeholder="监听地址"></el-input>
        </el-form-item>
        <el-form-item label="监听端口" prop="listeningPort">
          <el-input class="width-350" v-model.trim="formData.listeningPort" placeholder="监听端口"></el-input>
          <span class="port-tip el-icon-warning m-left-10">端口取值范围：0-65535</span>
        </el-form-item>
        <el-form-item label="其他参数（选填）" v-if="hardwareType === 'DOOR_LOCK'">
          <el-table class="m-bottom-10" :data="formData.otherParams" border size="mini">
            <el-table-column label="参数">
              <template slot-scope="scope">
                <el-input v-model="scope.row.value" placeholder="参数"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="key" label="参数代码"></el-table-column>
            <el-table-column prop="remark" label="参数说明"></el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <el-button>重置</el-button>
      <el-button @click="handleSave" type="primary">保存</el-button>
    </el-row>
  </div>
</template>
<script>
import { getHardwareList, saveConfig, getByBrandCode } from '@/api/pms/hardware/doorLock';
import { mapState } from 'vuex';
import axios from "axios";
export default {
  name: 'doorLock',
  data() {
    return {
      crumbs1: new Map([
          ['PMS'], ['硬件对接'], ['门锁']
      ]),
      crumbs2: new Map([
        ['PMS'], ['硬件对接'], ['身份证读卡器']
      ]),
      brandOpt: [],
      formData: {
        id: '',
        hardwareId: '',
        listeningAddress: '',
        listeningPort: '',
        otherParams: [
          { value: '', key: 'LockSoftware',
            remark: '指定门锁软件 Mifare卡门锁系统-1 Temic卡门锁系统-2' },
          { value: '', key: 'DBFlag',
            remark: '门锁软件数据库类型，0-单机版（ACCESS） 1-网络版（MSSQL）' },
          { value: '', key: 'DBServer',
            remark: '指定门锁系统数据库安装的位置。ACCESS： 门锁软件安装目录，如‘C:Program FilesXXXMHA V8.0’。 MSSQL：SQL Server所在服务器名。' },
          { value: '', key: 'Port',
            remark: '发行机端口0-USB， 1-COM1，2-COM2，3-COM3，4-COM4等' },
          { value: '', key: 'Breakfast', remark: '早餐标志. 1–有, 0–无.' },
          { value: '', key: 'OverFlag',
            remark: '是否覆盖当前卡上的内容。1-覆盖，当前卡不是新卡则自动注销（必须是客人卡）。0-不覆盖，不是新卡则返回。' }
        ]
      },
      rules: {
        hardwareId: [{ required: true, message: '硬件品牌不能有空！' }],
        listeningAddress: [{ required: true, message: '监听地址不能为空！' }],
        listeningPort: [{ required: true, message: '监听端口不能为空！' }]
      },
      method: '',
      connectRes: '',
      hardwareType: '',  // 当前硬件类型 门锁/身份证
      brandCode:''    // 当前门锁类型
    }
  },
  computed: {
    ...mapState(['hotelInfo'])
  },
  beforeRouteUpdate(to, from, next) {
    next()
    this.handlePublic()
  },
  mounted() {
    this.handlePublic()
  },
  methods: {
    // 公共部分
    handlePublic() {
      this.hardwareType = this.$route.params.type
      this.getLockConfig()
      this.getBrandList()
    },
    // 获取硬件配置信息
    getLockConfig() {
      const params = { hotelId: this.hotelInfo.id };
      getByBrandCode(params).then(({ success, data }) => {
        if (!success) return
        const hardwareList = data && data.find(item => item.hardwareType === this.hardwareType)
        if (hardwareList) {
          this.method = 'PUT'
          for (const k in this.formData) k in hardwareList && (this.formData[k] = hardwareList[k])
        } else {
          this.method = 'POST'
          for (const k in this.formData) k !== 'otherParams' && (this.formData[k] = '')
        }
      })
    },
    // 获取硬件品牌列表
    getBrandList() {
      const params = { hotelId: this.hotelInfo.id, hardwareType: this.hardwareType };
      getHardwareList(params).then(({ success, records }) => {
        if (success) {
          this.brandOpt = records.map(i => ({ id: i.id, brandName: i.brandName, brandCode: i.brandCode }))
        }
      })
    },
    // 硬件品牌下拉 筛选出 brandCode 的值
    handleCodeChange(event){
      this.brandOpt.forEach((item)=>{
        if(item.id === event){
          this.brandCode = item.brandCode
        }
      });
    },
    // 保存硬件配置信息
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return
        const params = { ...this.formData, hotelId: this.hotelInfo.id };
        this.method === 'POST' && delete params.id
        this.hardwareType === 'ID_CARD_READER' && delete params.otherParams
        params.brandCode = this.brandCode || this.brandOpt.find(i => i.id === this.formData.hardwareId).brandCode
        saveConfig(this.method, params).then(({ success }) => {
          if (success) {
            this.$message({ message: '保存门锁配置成功！', type: 'success' })
          }
        })
      })
    },
    // 检查连接
    handleConnect() {
      this.$refs.form.validate(valid => {
        if (!valid) return
        const brandCode = this.brandOpt.find(i => i.id === this.formData.hardwareId).brandCode
        const url = this.formData.listeningAddress + ':' + this.formData.listeningPort + '/lock/checkConnect' + `/${ brandCode }`;
        axios.post(url).then(({ data }) => {
          this.connectRes = `连接${ data ? '正常' : '失败' }`
          this.$message({ message: this.connectRes, type: 'success' })
        }).catch(err => {
          const message = err.response && err.response. data && err.response.data.message
          this.$message.error(message)
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.cont {
  .port-tip{ color: #FF9100 }
  .connect-tip { color: #07C43F; font-size: 16px; margin: 0 0 0 10px }
}
</style>
